export default [
  { path: "/", redirect: { name: "dashboard-ecommerce" } },
  //graph-analysis
  {
    path: "analysis",
    name: "graph-analysis-list",
    component: () =>
      import("@/views/apps/graph-analysis/GraphAnalysisList.vue"),
  },
  {
    path: "/apps/analysis/:flockId?",
    name: "graph-analysis-list-flock",
    component: () =>
      import("@/views/apps/graph-analysis/GraphAnalysisList.vue"),
  },
  {
    path: "/apps/dashboard/broiler",
    // path: "/apps/broiler-dashboard/:breed/:type",
    name: "dashboard-broiler",
    component: () =>
      import("@/views/dashboard/dashboard/BroilerDashboardDetail.vue"),
  },
  {
    path: "/apps/dashboard/shed/:shedId",
    // path: "/apps/broiler-dashboard/:breed/:type",
    name: "dashboard-broiler-shed",
    component: () => import("@/views/dashboard/dashboard/ShedDetail.vue"),
  },
  {
    path: "/apps/dashboard/breeder/shed/:shedId",
    // path: "/apps/broiler-dashboard/:breed/:type",
    name: "dashboard-breeder-shed",
    component: () =>
      import("@/views/dashboard/dashboard/BreederShedDetail.vue"),
  },
  {
    path: "/apps/dashboard/layer/shed/:shedId",
    // path: "/apps/broiler-dashboard/:breed/:type",
    name: "dashboard-layer-shed",
    component: () => import("@/views/dashboard/dashboard/LayerShedDetail.vue"),
  },
  {
    path: "/apps/dashboard/breeder",
    // path: "/apps/broiler-dashboard/:breed/:type",
    name: "dashboard-breeder",
    component: () =>
      import("@/views/dashboard/dashboard/BreederDashboardDetail.vue"),
  },
  {
    path: "/apps/dashboard/layer",
    // path: "/apps/broiler-dashboard/:breed/:type",
    name: "dashboard-layer",
    component: () =>
      import("@/views/dashboard/dashboard/LayerDashboardDetail.vue"),
  },
  // master-data
  {
    path: "/apps/master-data",
    name: "apps-master-data",
    component: () => import("@/views/apps/master/master-list/MasterList.vue"),
  },
  {
    path: "/apps/schedule",
    name: "apps-master-schedule",
    component: () =>
      import("@/views/apps/master/schedule-list/ScheduleList.vue"),
  },
  //Breed-Standard
  {
    path: "/apps/breed-standard/list",
    name: "apps-master-breed-standard",
    component: () =>
      import("@/views/apps/master/breed-standard/BreedStandardList.vue"),
  },
  // Roles
  {
    path: "/apps/roles",
    name: "apps-roles",
    component: () => import("@/views/apps/roles/role-list/RoleList.vue"),
  },
  // Permission
  {
    path: "/apps/permission",
    name: "apps-permission",
    component: () => import("@/views/apps/permission/Permission.vue"),
  },
  // User
  // {
  // 	path: "/apps/",n",
  // 	component: () => import("@/views/apps/permission/Permission.vue"),
  // },
  // {
  // 	path: "/apps/settings",
  // 	name: "apps-settings",
  // 	component: () => import("@/views/apps/settings/Settings.vue"),
  // },
  // *===============================================---*
  // *--------- USER ---- ---------------------------------------*
  // *===============================================---*
  // owner
  {
    path: "/apps/owner/list",
    name: "apps-owner-list",
    props: {
      permissionName: "Owner",
    },
    component: () => import("@/views/apps/user/components/List.vue"),
  },
  // admin
  {
    path: "/apps/admin/list",
    name: "apps-admin-list",
    props: {
      permissionName: "Admin",
    },
    component: () => import("@/views/apps/user/components/List.vue"),
  },
  {
    path: "/apps/manager/list",
    name: "apps-manager-list",
    props: {
      permissionName: "Manager",
    },
    component: () => import("@/views/apps/user/components/List.vue"),
  },
  {
    path: "/apps/supervisor/list",
    name: "apps-supervisor-list",
    props: {
      permissionName: "Supervisor",
    },
    component: () => import("@/views/apps/user/components/List.vue"),
  },
  {
    path: "/apps/assistant/list",
    name: "apps-assistant-list",
    props: {
      permissionName: "AssistantManager",
    },
    component: () => import("@/views/apps/user/components/List.vue"),
  },
  {
    path: "/apps/admin/add",
    name: "apps-admin-add",
    component: () => import("@/views/apps/user/components/Add-form.vue"),
  },
  {
    path: "/apps/owner/add",
    name: "apps-owner-list",
    component: () => import("@/views/apps/user/components/Add-form.vue"),
  },
  {
    path: "/apps/assistant/add",
    name: "apps-assistant-list",
    component: () => import("@/views/apps/user/components/Add-form.vue"),
  },
  {
    path: "/apps/manager/add",
    name: "apps-manager-list",
    component: () => import("@/views/apps/user/components/Add-form.vue"),
  },
  {
    path: "/apps/supervisor/add",
    name: "apps-supervisor-list",
    component: () => import("@/views/apps/user/components/Add-form.vue"),
  },
  // edit form
  {
    path: "/apps/owner/edit/:userId",
    name: "apps-owner-list",
    component: () => import("@/views/apps/user/components/Edit-form.vue"),
  },
  {
    path: "/apps/admin/edit/:userId",
    name: "apps-owner-list",
    component: () => import("@/views/apps/user/components/Edit-form.vue"),
  },
  {
    path: "/apps/assistant/edit/:userId",
    name: "apps-assistant-list",
    component: () => import("@/views/apps/user/components/Edit-form.vue"),
  },
  {
    path: "/apps/manager/edit/:userId",
    name: "apps-manager-list",
    component: () => import("@/views/apps/user/components/Edit-form.vue"),
  },
  {
    path: "/apps/supervisor/edit/:userId",
    name: "apps-supervisor-list",
    component: () => import("@/views/apps/user/components/Edit-form.vue"),
  },
  // view form
  {
    path: "/apps/owner/view/:userId",
    name: "apps-owner-view",
    component: () => import("@/views/apps/user/components/View-form.vue"),
  },
  {
    path: "/apps/admin/view/:userId",
    name: "apps-admin-list",
    component: () => import("@/views/apps/user/components/View-form.vue"),
  },
  {
    path: "/apps/assistant/view/:userId",
    name: "apps-assistant-list",
    component: () => import("@/views/apps/user/components/View-form.vue"),
  },
  {
    path: "/apps/manager/view/:userId",
    name: "apps-manager-list",
    component: () => import("@/views/apps/user/components/View-form.vue"),
  },
  {
    path: "/apps/supervisor/view/:userId",
    name: "apps-supervisor-list",
    component: () => import("@/views/apps/user/components/View-form.vue"),
  },
  // Poultrac
  {
    path: "/apps/farm/list",
    name: "apps-farm-list",
    component: () => import("@/views/apps/hatchery/farm-list/FarmList.vue"),
  },
  {
    path: "/apps/farm/add",
    name: "apps-farm-add",
    component: () => import("@/views/apps/hatchery/farm-add/FarmAdd.vue"),
  },
  {
    path: "/apps/farm/:farmId/view",
    name: "apps-farm-view",
    component: () => import("@/views/apps/hatchery/farm-detail/FarmView.vue"),
  },
  {
    path: "/apps/farm/:farmId/shed/:shedId/view",
    name: "apps-shed-view",
    component: () => import("@/views/apps/shed/ShedView.vue"),
  },
  {
    path: "/apps/farm/:farmId/edit",
    name: "apps-farm-edit",
    component: () => import("@/views/apps/hatchery/farm-edit/FarmEdit.vue"),
  },

  // Purchase Company
  {
    path: "/apps/company-purchase",
    name: "apps-company-purchase",
    component: () =>
      import(
        "@/views/apps/company-purchase/company-purchase-list/PurchaseList.vue"
      ),
  },
  {
    path: "/apps/company-purchase/chicks/add",
    name: "apps-company-purchase-chicks-add",
    component: () =>
      import(
        "@/views/apps/company-purchase/company-purchase-add/ChicksAdd.vue"
      ),
  },
  {
    path: "/apps/company-purchase/vaccine/add",
    name: "apps-company-purchase-vaccine-add",
    component: () =>
      import(
        "@/views/apps/company-purchase/company-purchase-add/VaccineAdd.vue"
      ),
  },
  {
    path: "/apps/company-purchase/feed/add",
    name: "apps-company-purchase-feed-add",
    component: () =>
      import("@/views/apps/company-purchase/company-purchase-add/FeedAdd.vue"),
  },
  {
    path: "/apps/company-purchase/medicine/add",
    name: "apps-company-purchase-medicine-add",
    component: () =>
      import(
        "@/views/apps/company-purchase/company-purchase-add/MedicineAdd.vue"
      ),
  },
  {
    path: "/apps/company-purchase/other/add",
    name: "apps-company-purchase-other-add",
    component: () =>
      import("@/views/apps/company-purchase/company-purchase-add/OtherAdd.vue"),
  },
  // Inventory Company
  {
    path: "/apps/company-inventory",
    name: "apps-company-inventory",
    component: () =>
      import(
        "@/views/apps/company-inventory/company-inventory-list/InventoryList.vue"
      ),
  },
  {
    path: "/apps/company-inventory/add",
    name: "apps-company-inventory-add",
    component: () =>
      import(
        "@/views/apps/company-inventory/company-inventory-add/InventoryAdd.vue"
      ),
  },
  {
    path: "/apps/company-inventory/edit",
    name: "apps-company-inventory-edit",
    component: () =>
      import(
        "@/views/apps/company-inventory/company-inventory-edit/InventoryEdit.vue"
      ),
  },

  //Supply-Farm
  {
    path: "/apps/supply-farm/list",
    name: "apps-supply-farm-list",
    component: () =>
      import("@/views/apps/supply-farm/supply-farm-list/SupplyFarmList.vue"),
  },
  {
    path: "/apps/supply-farm/medicine/add",
    name: "apps-supply-farm-medicine",
    component: () =>
      import("@/views/apps/supply-farm/supply-farm-add/MedicineAdd.vue"),
  },
  {
    path: "/apps/supply-farm/vaccine/add",
    name: "apps-supply-farm-vaccine",
    component: () =>
      import("@/views/apps/supply-farm/supply-farm-add/VaccineAdd.vue"),
  },
  {
    path: "/apps/supply-farm/feed/add",
    name: "apps-supply-farm-feed",
    component: () =>
      import("@/views/apps/supply-farm/supply-farm-add/FeedAdd.vue"),
  },
  {
    path: "/apps/supply-farm/chicks/add",
    name: "apps-supply-farm-chicks",
    component: () =>
      import("@/views/apps/supply-farm/supply-farm-add/ChicksAdd.vue"),
  },

  {
    path: "/apps/manage-farm/:farmId",
    name: "manage-farm",
    redirect: { name: "hatchery-dashboard-ecommerce" },
    component: () =>
      import("@/views/apps/manage-company/manage-farm/ManageFarm.vue"),
    children: [
      {
        path: "dashboard/ecommerce",
        name: "hatchery-dashboard-ecommerce",
        component: () => import("@/views/dashboard/ecommerce/Ecommerce.vue"),
      },
      {
        path: "dashboard/broiler",
        // path: "/apps/broiler-dashboard/:breed/:type",
        name: "dashboard-broiler",
        component: () =>
          import("@/views/dashboard/dashboard/BroilerDashboardDetail.vue"),
      },
      {
        path: "dashboard/shed/:shedId",
        // path: "/apps/broiler-dashboard/:breed/:type",
        name: "dashboard-broiler-shed",
        component: () => import("@/views/dashboard/dashboard/ShedDetail.vue"),
      },
      {
        path: "dashboard/breeder/shed/:shedId",
        // path: "/apps/broiler-dashboard/:breed/:type",
        name: "dashboard-breeder-shed",
        component: () =>
          import("@/views/dashboard/dashboard/BreederShedDetail.vue"),
      },
      {
        path: "dashboard/layer/shed/:shedId",
        // path: "/apps/broiler-dashboard/:breed/:type",
        name: "dashboard-layer-shed",
        component: () =>
          import("@/views/dashboard/dashboard/LayerShedDetail.vue"),
      },
      {
        path: "dashboard/breeder",
        // path: "/apps/broiler-dashboard/:breed/:type",
        name: "dashboard-breeder",
        component: () =>
          import("@/views/dashboard/dashboard/BreederDashboardDetail.vue"),
      },
      {
        path: "dashboard/layer",
        // path: "/apps/broiler-dashboard/:breed/:type",
        name: "dashboard-layer",
        component: () =>
          import("@/views/dashboard/dashboard/LayerDashboardDetail.vue"),
      },
      {
        path: "purchase",
        name: "apps-purchase",
        component: () =>
          import("@/views/apps/purchase/purchase-list/PurchaseList.vue"),
      },
      {
        path: "purchase/chicks/add",
        name: "apps-purchase-chicks-add",
        component: () =>
          import("@/views/apps/purchase/purchase-add/ChicksAdd.vue"),
      },
      {
        path: "purchase/vaccine/add",
        name: "apps-purchase-vaccine-add",
        component: () =>
          import("@/views/apps/purchase/purchase-add/VaccineAdd.vue"),
      },
      {
        path: "purchase/feed/add",
        name: "apps-purchase-feed-add",
        component: () =>
          import("@/views/apps/purchase/purchase-add/FeedAdd.vue"),
      },
      {
        path: "purchase/medicine/add",
        name: "apps-purchase-medicine-add",
        component: () =>
          import("@/views/apps/purchase/purchase-add/MedicineAdd.vue"),
      },
      {
        path: "purchase/other/add",
        name: "apps-purchase-other-add",
        component: () =>
          import("@/views/apps/purchase/purchase-add/OtherAdd.vue"),
      },
      {
        path: "purchase/egg-stock/edit",
        name: "apps-purchase-eggStock-edit",
        component: () =>
          import("@/views/apps/purchase/purchase-edit/EggStockEdit.vue"),
      },
      {
        path: "purchase/vaccine/edit",
        name: "apps-purchase-vaccine-edit",
        component: () =>
          import("@/views/apps/purchase/purchase-edit/VaccineEdit.vue"),
      },
      {
        path: "purchase/medicine/edit",
        name: "apps-purchase-medicine-edit",
        component: () =>
          import("@/views/apps/purchase/purchase-edit/MedicineEdit.vue"),
      },
      // inventory
      {
        path: "inventory",
        name: "apps-inventory",
        component: () =>
          import("@/views/apps/inventory/inventory-list/InventoryList.vue"),
      },
      {
        path: "inventory/add",
        name: "apps-inventory-add",
        component: () =>
          import("@/views/apps/inventory/inventory-add/InventoryAdd.vue"),
      },
      {
        path: "inventory/edit",
        name: "apps-inventory-edit",
        component: () =>
          import("@/views/apps/inventory/inventory-edit/InventoryEdit.vue"),
      },
      // data Entry
      {
        path: "shed/:shedId/data-entry/:flockId/feed/add",
        name: "apps-data-entry-feed-add",
        component: () =>
          import("@/views/apps/data-entry/data-entry-add/FeedDataEntryAdd.vue"),
      },
      {
        path: "shed/:shedId/data-entry/:flockId/weight/add",
        name: "apps-data-entry-weight-add",
        component: () =>
          import("@/views/apps/data-entry/data-entry-add/WeightListAdd.vue"),
      },
      {
        path: "shed/:shedId/data-entry/:flockId/bird-sale/add",
        name: "apps-data-entry-bird-sale-add",
        component: () =>
          import("@/views/apps/data-entry/data-entry-add/BirdSaleAdd.vue"),
      },
      {
        path: "shed/:shedId/data-entry/:flockId/egg/add",
        name: "apps-data-entry-egg-add",
        component: () =>
          import("@/views/apps/data-entry/data-entry-add/EggProductionAdd.vue"),
      },
      {
        path: "shed/:shedId/data-entry/:flockId/egg-weight/add",
        name: "apps-data-entry-egg-weight-add",
        component: () =>
          import("@/views/apps/data-entry/data-entry-add/EggWeightAdd.vue"),
      },
      {
        path: "shed/:shedId/data-entry/:flockId/vaccine/add",
        name: "apps-data-entry-vaccine-add",
        component: () =>
          import("@/views/apps/data-entry/data-entry-add/VaccineListAdd.vue"),
      },
      {
        path: "shed/:shedId/data-entry/:flockId/medicine/add",
        name: "apps-data-entry-medicine-add",
        component: () =>
          import("@/views/apps/data-entry/data-entry-add/MedicineAdd.vue"),
      },
      {
        path: "shed/:shedId/data-entry/:flockId/mortality/add",
        name: "apps-data-entry-mortality-add",
        component: () =>
          import("@/views/apps/data-entry/data-entry-add/MortalityListAdd.vue"),
      },
      {
        path: "shed/:shedId/data-entry/:flockId/water-consumption/add",
        name: "apps-data-entry-water-consumption-add",
        component: () =>
          import(
            "@/views/apps/data-entry/data-entry-add/WaterCompositionAdd.vue"
          ),
      },
      {
        path: "shed/:shedId/data-entry/:flockId/treatment/add",
        name: "apps-data-entry-treatment-add",
        component: () =>
          import("@/views/apps/data-entry/data-entry-add/TreatmentAdd.vue"),
      },
      {
        path: "shed/:shedId/data-entry/:flockId/chick-placement/add",
        name: "apps-data-entry-chick-placement-add",
        component: () =>
          import("@/views/apps/data-entry/data-entry-add/ChickPlacementAdd.vue"),
      },
      {
        path: "shed/:shedId/data-entry/:flockId/chick-transfer/add",
        name: "apps-data-entry-chick-transfer-add",
        component: () =>
          import("@/views/apps/data-entry/data-entry-add/ChickTransferAdd.vue"),
      },
      // expense Entry
      {
        path: "expense-entry",
        name: "apps-expense-list",
        component: () =>
          import("@/views/apps/expense-entry/expense-list/ExpenseList"),
      },
      {
        path: "expense-entry",
        name: "apps-expense-add",
        component: () =>
          import("@/views/apps/expense-entry/expense-add/ExpenseAdd"),
      },
      {
        path: "expense-entry/:expenseId",
        name: "apps-expense-edit",
        component: () =>
          import("@/views/apps/expense-entry/expense-edit/ExpenseEdit"),
      },
      // machine list
      {
        path: "machine/setter",
        name: "apps-machine-setter",
        component: () =>
          import("@/views/apps/machine/machine-list/SetterList.vue"),
      },
      {
        path: "machine/hatcher",
        name: "apps-machine-hatcher",
        component: () =>
          import("@/views/apps/machine/machine-list/HatcherList.vue"),
      },
      //   timeline
      {
        path: "machine/:machineId/device/overview/:hatchId/timeline",
        name: "apps-machine-device-timeline",
        component: () => import("@/views/apps/machine/timeline/Timeline.vue"),
      },
      //   hatcher-timeline
      {
        path: "machine/:machineId/device/overview/:hatchId/timeline",
        name: "apps-machine-device-hatcher-timeline",
        component: () =>
          import("@/views/apps/machine/timeline/HatcherTimeline.vue"),
      },
      // graph
      {
        path: "machine/:machineId/device/overview/:hatchId/graph",
        name: "apps-machine-device-graph",
        component: () => import("@/views/apps/machine/graph/Graph.vue"),
      },

      // machine view
      {
        path: "machine/hatcher/view",
        name: "apps-machine-hatcher-view",
        component: () =>
          import("@/views/apps/machine/machine-list/HatcherView.vue"),
      },
      {
        path: "machine/setter/view",
        name: "apps-machine-setter-view",
        component: () =>
          import("@/views/apps/machine/machine-list/SetterView.vue"),
      },
      // device-add
      {
        path: "machine/hatcher/:machineId/device",
        name: "apps-machine-hatcher-device",
        component: () =>
          import("@/views/apps/machine/device/HatcherDevice.vue"),
      },
      {
        path: "machine/setter/:machineId/device",
        name: "apps-machine-setter-device",
        component: () => import("@/views/apps/machine/device/SetterDevice.vue"),
      },

      // overview
      {
        path: "machine/:machineId/device/overview/:hatchId",
        name: "apps-machine-hatcher-device-overview",
        component: () =>
          import("@/views/apps/machine/machine-list/HatcherOverView.vue"),
      },
      {
        path: "machine/:machineId/device/overview/:hatchId",
        name: "apps-machine-setter-device-overview",
        component: () =>
          import("@/views/apps/machine/machine-list/SetterOverview.vue"),
      },

      // hatch Summary
      {
        path: "hatchSummary/list",
        name: "apps-hatchSummary-list",
        component: () =>
          import("@/views/apps/hatch-summary/hatch-list/HatchSummary.vue"),
      },
      // alarm
      {
        path: "alarm",
        name: "apps-alarm",
        component: () => import("@/views/apps/alarm/alarm-list/AlarmList.vue"),
      },
      // action-list
      {
        path: "action-list",
        name: "apps-action-list",
        component: () =>
          import("@/views/apps/action/action-list/ActionList.vue"),
      },
      // sale
      {
        path: "sale",
        name: "apps-sale",
        component: () => import("@/views/apps/sale/sale-list/SaleList.vue"),
      },
      {
        path: "sale/egg-sale",
        name: "apps-sale-egg-sale",
        component: () => import("@/views/apps/sale/egg-sale/EggSale.vue"),
      },
      {
        path: "sale/transfer-egg-sale",
        name: "apps-sale-transfer-egg-sale",
        component: () =>
          import(
            "@/views/apps/sale/transfer-eggs-sale/TransferEggSaleList.vue"
          ),
      },
      {
        path: "sale/litter-sale",
        name: "apps-sale-litter-sale",
        component: () =>
          import("@/views/apps/sale/litter-sale/LitterSaleList.vue"),
      },
      {
        path: "sale/litter-sale",
        name: "apps-sale-chick-sale-add",
        component: () =>
          import("@/views/apps/sale/litter-sale/add-litter/AddLitterSale.vue"),
      },
      //Data-Entry
      {
        path: "data-entry/list",
        name: "apps-data-entry-list",
        component: () =>
          import("@/views/apps/data-entry/data-entry-list/DataEntryList.vue"),
      },

      // Reports
      {
        path: "reports",
        name: "apps-reports",
        component: () => import("@/views/apps/reports/Reports.vue"),
      },
      {
        path: "current-flock",
        name: "apps-reports-current-flock",
        component: () => import("@/views/apps/reports/CurrentReport.vue"),
      },
      {
        path: "old-flock",
        name: "apps-reports-old-flock",
        component: () => import("@/views/apps/reports/OldReport.vue"),
      },
      // Economic Report
      {
        path: "/apps/economic-report",
        name: "apps-economic-report",
        component: () => import("@/views/apps/reports/EconomicReport.vue"),
      },
      // all device
      {
        path: "device/machine-list",
        name: "apps-device-machine-list",
        component: () =>
          import("@/views/apps/device-manage/DeviceManageList.vue"),
      },
      // settings
      {
        path: "settings/hatchery-list",
        name: "apps-settings-hatchery-list",
        component: () => import("@/views/apps/settings/SettingHatchery.vue"),
      },
      {
        path: "settings",
        name: "apps-settings",
        component: () => import("@/views/apps/settings/Settings.vue"),
      },
      {
        path: "shed/:shedId/data-entry/:flockId",
        name: "apps-data-entry-flock",
        component: () =>
          import("@/views/apps/data-entry/data-entry-list/DataEntryList.vue"),
      },
      {
        path: "shed/:shedId",
        name: "apps-data-entry-shed",
        component: () =>
          import("@/views/apps/data-entry/data-entry-list/DataEntryList.vue"),
      },
      // User
      // {
      // 	path: "/apps/",
      // 	name: "apps-permission",
      // 	component: () => import("@/views/apps/permission/Permission.vue"),
      // },
      // {
      // 	path: "/apps/settings",
      // 	name: "apps-settings",
      // 	component: () => import("@/views/apps/settings/Settings.vue"),
      // },
      // *===============================================---*
      // *--------- USER ---- ---------------------------------------*
      // *===============================================---*
    ],
  },
];
