import Vue from "vue";
import { ToastPlugin, ModalPlugin } from "bootstrap-vue";
import VueCompositionAPI from "@vue/composition-api";

import i18n from "@/libs/i18n";
import router from "./router";
import store from "./store";
import App from "./App.vue";

// Global Components
import "./global-components";

// 3rd party plugins
import "@axios";
import "@/libs/acl";
import "@/libs/portal-vue";
import "@/libs/clipboard";
import "@/libs/toastification";
import "@/libs/sweet-alerts";
import "@/libs/vue-select";
import "@/libs/tour";

// Axios Mock Adapter
import "@/@fake-db/db";

import vSelect from "vue-select";
Vue.component("v-select", vSelect);

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);
 
// Bootstrap Import
import BootstrapVue from "bootstrap-vue";
Vue.use(BootstrapVue);


// Composition API
Vue.use(VueCompositionAPI);

import mixin from "./mixins";
Vue.mixin(mixin);

// vee-validate
import { ValidationProvider, extend, ValidationObserver } from "vee-validate";
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
import * as rules from "vee-validate/dist/rules";
import eng from "vee-validate/dist/locale/en";

// loop over all rules
for (let rule in rules) {
	extend(rule, {
		...rules[rule], // add the rule
		message: eng.messages[rule], // add its message
	});
}

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require("@core/assets/fonts/feather/iconfont.css"); // For form-wizard

// import core styles
require("@core/scss/core.scss");

// import assets styles
require("@/assets/scss/style.scss");

window.axios = require("axios");
window.axios.defaults.baseURL = "http://65.2.43.224/api";
window.axios.defaults.withCredentials = true;

Vue.config.productionTip = false;

new Vue({
	router,
	store,
	i18n,
	render: (h) => h(App),
}).$mount("#app");
