export default [
  { path: "/", redirect: { name: "apps-company-summary" } },
  {
    path: "/apps/company",
    name: "apps-company-detail",
    component: () => import("@/views/apps/company/CompanyList.vue"),
  },
  {
    path: "/apps/dashboard/broiler",
    // path: "/apps/broiler-dashboard/:breed/:type",
    name: "dashboard-broiler",
    component: () =>
      import("@/views/dashboard/dashboard/BroilerDashboardDetail.vue"),
  },
  {
    path: "/apps/dashboard/shed/:shedId",
    // path: "/apps/broiler-dashboard/:breed/:type",
    name: "dashboard-broiler-shed",
    component: () => import("@/views/dashboard/dashboard/ShedDetail.vue"),
  },
  {
    path: "/apps/dashboard/breeder/shed/:shedId",
    // path: "/apps/broiler-dashboard/:breed/:type",
    name: "dashboard-breeder-shed",
    component: () =>
      import("@/views/dashboard/dashboard/BreederShedDetail.vue"),
  },
  {
    path: "/apps/dashboard/layer/shed/:shedId",
    // path: "/apps/broiler-dashboard/:breed/:type",
    name: "dashboard-layer-shed",
    component: () => import("@/views/dashboard/dashboard/LayerShedDetail.vue"),
  },
  {
    path: "/apps/dashboard/breeder",
    // path: "/apps/broiler-dashboard/:breed/:type",
    name: "dashboard-breeder",
    component: () =>
      import("@/views/dashboard/dashboard/BreederDashboardDetail.vue"),
  },
  {
    path: "/apps/dashboard/layer",
    // path: "/apps/broiler-dashboard/:breed/:type",
    name: "dashboard-layer",
    component: () =>
      import("@/views/dashboard/dashboard/LayerDashboardDetail.vue"),
  },
  {
    path: "/apps/company/add",
    name: "apps-company-add",
    component: () => import("@/views/apps/company/company-add/CompanyAdd.vue"),
  },
  {
    path: "/apps/company/:companyId",
    name: "apps-company-edit",
    component: () =>
      import("@/views/apps/company/company-edit/CompanyEdit.vue"),
  },
  {
    path: "/apps/company/summary",
    name: "apps-company-summary",
    component: () => import("@/views/apps/company-summary/CompanySummary.vue"),
  },
  {
    path: "/apps/company/hatchery/summary/:companyId",
    name: "apps-company-hatchery-summary",
    component: () =>
      import("@/views/apps/company-summary/CompanyHatcherySummary.vue"),
  },
  {
    path: "/apps/roles",
    name: "apps-roles",
    component: () => import("@/views/apps/roles/role-list/RoleList.vue"),
  },

  {
    path: "/apps/permission",
    name: "apps-permission",
    component: () => import("@/views/apps/modules/SuperAdminModules.vue"),
  },

  {
    path: "/apps/manage-company/:companyId",
    name: "manage-company",
    component: () => import("@/views/apps/manage-company/ManageCompany.vue"),
    redirect: { name: "company-dashboard-ecommerce" },
    children: [
      {
        path: "dashboard/analytics",
        name: "company-dashboard-analytics",
        component: () => import("@/views/dashboard/analytics/Analytics.vue"),
      },

      {
        path: "dashboard/ecommerce",
        name: "company-dashboard-ecommerce",
        component: () => import("@/views/dashboard/ecommerce/Ecommerce.vue"),
      },
      //graph-analysis
      {
        path: "analysis",
        name: "graph-analysis-list",
        component: () =>
          import("@/views/apps/graph-analysis/GraphAnalysisList.vue"),
      },
      {
        path: "/apps/analysis/:flockId?",
        name: "graph-analysis-list-flock",
        component: () =>
          import("@/views/apps/graph-analysis/GraphAnalysisList.vue"),
      },
      // master-data
      {
        path: "master-data",
        name: "apps-master-data",
        component: () =>
          import("@/views/apps/master/master-list/MasterList.vue"),
      },
      {
        path: "schedule",
        name: "apps-master-schedule",
        component: () =>
          import("@/views/apps/master/schedule-list/ScheduleList.vue"),
      },
      {
        path: "breed-standard/list",
        name: "apps-master-breed-standard",
        component: () =>
          import("@/views/apps/master/breed-standard/BreedStandardList.vue"),
      },

      // Purchase Company
      {
        path: "/apps/company-purchase",
        name: "apps-company-purchase",
        component: () =>
          import(
            "@/views/apps/company-purchase/company-purchase-list/PurchaseList.vue"
          ),
      },
      {
        path: "/apps/company-purchase/chicks/add",
        name: "apps-company-purchase-chicks-add",
        component: () =>
          import(
            "@/views/apps/company-purchase/company-purchase-add/ChicksAdd.vue"
          ),
      },
      {
        path: "/apps/company-purchase/vaccine/add",
        name: "apps-company-purchase-vaccine-add",
        component: () =>
          import(
            "@/views/apps/company-purchase/company-purchase-add/VaccineAdd.vue"
          ),
      },
      {
        path: "/apps/company-purchase/feed/add",
        name: "apps-company-purchase-feed-add",
        component: () =>
          import(
            "@/views/apps/company-purchase/company-purchase-add/FeedAdd.vue"
          ),
      },
      {
        path: "/apps/company-purchase/medicine/add",
        name: "apps-company-purchase-medicine-add",
        component: () =>
          import(
            "@/views/apps/company-purchase/company-purchase-add/MedicineAdd.vue"
          ),
      },
      {
        path: "/apps/company-purchase/other/add",
        name: "apps-company-purchase-other-add",
        component: () =>
          import(
            "@/views/apps/company-purchase/company-purchase-add/OtherAdd.vue"
          ),
      },
      // Inventory Company
      {
        path: "/apps/company-inventory",
        name: "apps-company-inventory",
        component: () =>
          import(
            "@/views/apps/company-inventory/company-inventory-list/InventoryList.vue"
          ),
      },
      {
        path: "/apps/company-inventory/add",
        name: "apps-company-inventory-add",
        component: () =>
          import(
            "@/views/apps/company-inventory/company-inventory-add/InventoryAdd.vue"
          ),
      },
      {
        path: "/apps/company-inventory/edit",
        name: "apps-company-inventory-edit",
        component: () =>
          import(
            "@/views/apps/company-inventory/company-inventory-edit/InventoryEdit.vue"
          ),
      },

      // Roles
      {
        path: "roles",
        name: "apps-roles",
        component: () => import("@/views/apps/roles/role-list/RoleList.vue"),
      },
      // Permission
      {
        path: "permission",
        name: "apps-permission",
        component: () => import("@/views/apps/modules/SuperAdminModules.vue"),
      },
      // User
      // {
      // 	path: "/apps/",
      // 	name: "apps-permission",
      // 	component: () => import("@/views/apps/permission/Permission.vue"),
      // },
      // {
      // 	path: "/apps/settings",
      // 	name: "apps-settings",
      // 	component: () => import("@/views/apps/settings/Settings.vue"),
      // },
      // *===============================================---*
      // *--------- USER ---- ---------------------------------------*
      // *===============================================---*
      // owner
      {
        path: "owner/list",
        name: "apps-owner-list",
        props: {
          permissionName: "Owner",
        },
        component: () => import("@/views/apps/user/components/List.vue"),
      },
      // admin
      {
        path: "admin/list",
        name: "apps-admin-list",
        props: {
          permissionName: "Admin",
        },
        component: () => import("@/views/apps/user/components/List.vue"),
      },
      {
        path: "manager/list",
        name: "apps-manager-list",
        props: {
          permissionName: "Manager",
        },
        component: () => import("@/views/apps/user/components/List.vue"),
      },
      {
        path: "supervisor/list",
        name: "apps-supervisor-list",
        props: {
          permissionName: "Supervisor",
        },
        component: () => import("@/views/apps/user/components/List.vue"),
      },
      {
        path: "assistant/list",
        name: "apps-assistant-list",
        props: {
          permissionName: "AssistantManager",
        },
        component: () => import("@/views/apps/user/components/List.vue"),
      },
      {
        path: "admin/add",
        name: "apps-admin-add",
        component: () => import("@/views/apps/user/components/Add-form.vue"),
      },
      {
        path: "owner/add",
        name: "apps-owner-add",
        component: () => import("@/views/apps/user/components/Add-form.vue"),
      },
      {
        path: "assistant/add",
        name: "apps-assistant-add",
        component: () => import("@/views/apps/user/components/Add-form.vue"),
      },
      {
        path: "manager/add",
        name: "apps-manager-list",
        component: () => import("@/views/apps/user/components/Add-form.vue"),
      },
      {
        path: "supervisor/add",
        name: "apps-supervisor-list",
        component: () => import("@/views/apps/user/components/Add-form.vue"),
      },
      // edit form
      {
        path: "owner/edit/:userId",
        name: "apps-owner-edit",
        component: () => import("@/views/apps/user/components/Edit-form.vue"),
      },
      {
        path: "admin/edit/:userId",
        name: "apps-admin-list",
        component: () => import("@/views/apps/user/components/Edit-form.vue"),
      },
      {
        path: "assistant/edit/:userId",
        name: "apps-assistant-list",
        component: () => import("@/views/apps/user/components/Edit-form.vue"),
      },
      {
        path: "manager/edit/:userId",
        name: "apps-manager-list",
        component: () => import("@/views/apps/user/components/Edit-form.vue"),
      },
      {
        path: "supervisor/edit/:userId",
        name: "apps-supervisor-list",
        component: () => import("@/views/apps/user/components/Edit-form.vue"),
      },
      // Poultrac
      {
        path: "farm/list",
        name: "apps-farm-list",
        component: () => import("@/views/apps/hatchery/farm-list/FarmList.vue"),
      },
      {
        path: "farm/add",
        name: "apps-farm-add",
        component: () => import("@/views/apps/hatchery/farm-add/FarmAdd.vue"),
      },
      {
        path: "/apps/farm/:farmId/view/",
        name: "apps-farm-view",
        component: () =>
          import("@/views/apps/hatchery/farm-detail/FarmView.vue"),
      },
      {
        path: "farm/:farmId/edit",
        name: "apps-farm-edit",
        component: () => import("@/views/apps/hatchery/farm-edit/FarmEdit.vue"),
      },

      {
        path: "manage-farm/:farmId",
        name: "manage-farm",
        redirect: { name: "hatchery-dashboard-ecommerce" },
        component: () =>
          import("@/views/apps/manage-company/manage-farm/ManageFarm.vue"),
        children: [
          {
            path: "/apps/dashboard/broiler",
            name: "dashboard-broiler-farm",
            component: () =>
              import("@/views/dashboard/dashboard/BroilerDashboardDetail.vue"),
          },
          {
            path: "/apps/dashboard/shed/:shedId",
            // path: "/apps/broiler-dashboard/:breed/:type",
            name: "dashboard-broiler-shed",
            component: () =>
              import("@/views/dashboard/dashboard/ShedDetail.vue"),
          },
          {
            path: "/apps/dashboard/breeder/shed/:shedId",
            // path: "/apps/broiler-dashboard/:breed/:type",
            name: "dashboard-breeder-shed",
            component: () =>
              import("@/views/dashboard/dashboard/BreederShedDetail.vue"),
          },
          {
            path: "/apps/dashboard/layer/shed/:shedId",
            // path: "/apps/broiler-dashboard/:breed/:type",
            name: "dashboard-layer-shed",
            component: () =>
              import("@/views/dashboard/dashboard/LayerShedDetail.vue"),
          },
          {
            path: "/apps/dashboard/breeder",
            // path: "/apps/broiler-dashboard/:breed/:type",
            name: "dashboard-breeder",
            component: () =>
              import("@/views/dashboard/dashboard/BreederDashboardDetail.vue"),
          },
          {
            path: "/apps/dashboard/layer",
            // path: "/apps/broiler-dashboard/:breed/:type",
            name: "dashboard-layer",
            component: () =>
              import("@/views/dashboard/dashboard/LayerDashboardDetail.vue"),
          },
          {
            path: "dashboard/ecommerce",
            name: "hatchery-dashboard-ecommerce",
            component: () =>
              import("@/views/dashboard/ecommerce/Ecommerce.vue"),
          },
          {
            path: "purchase",
            name: "apps-purchase",
            component: () =>
              import("@/views/apps/purchase/purchase-list/PurchaseList.vue"),
          },
          {
            path: "purchase/chicks/add",
            name: "apps-purchase-chicks-add",
            component: () =>
              import("@/views/apps/purchase/purchase-add/ChicksAdd.vue"),
          },
          {
            path: "purchase/feed/add",
            name: "apps-purchase-feed-add",
            component: () =>
              import("@/views/apps/purchase/purchase-add/FeedAdd.vue"),
          },
          {
            path: "purchase/vaccine/add",
            name: "apps-purchase-vaccine-add",
            component: () =>
              import("@/views/apps/purchase/purchase-add/VaccineAdd.vue"),
          },
          {
            path: "purchase/medicine/add",
            name: "apps-purchase-medicine-add",
            component: () =>
              import("@/views/apps/purchase/purchase-add/MedicineAdd.vue"),
          },
          {
            path: "purchase/other/add",
            name: "apps-purchase-other-add",
            component: () =>
              import("@/views/apps/purchase/purchase-add/OtherAdd.vue"),
          },
          {
            path: "purchase/egg-stock/edit",
            name: "apps-purchase-eggStock-edit",
            component: () =>
              import("@/views/apps/purchase/purchase-edit/EggStockEdit.vue"),
          },
          {
            path: "purchase/vaccine/edit",
            name: "apps-purchase-vaccine-edit",
            component: () =>
              import("@/views/apps/purchase/purchase-edit/VaccineEdit.vue"),
          },
          {
            path: "purchase/medicine/edit",
            name: "apps-purchase-medicine-edit",
            component: () =>
              import("@/views/apps/purchase/purchase-edit/MedicineEdit.vue"),
          },
          // inventory
          {
            path: "inventory",
            name: "apps-inventory",
            component: () =>
              import("@/views/apps/inventory/inventory-list/InventoryList.vue"),
          },
          {
            path: "inventory/add",
            name: "apps-inventory-add",
            component: () =>
              import("@/views/apps/inventory/inventory-add/InventoryAdd.vue"),
          },
          {
            path: "inventory/edit",
            name: "apps-inventory-edit",
            component: () =>
              import("@/views/apps/inventory/inventory-edit/InventoryEdit.vue"),
          },
          // data Entry

          {
            path: "data-entry/:flockId/feed/add",
            name: "apps-data-entry-feed-add",
            component: () =>
              import(
                "@/views/apps/data-entry/data-entry-add/FeedDataEntryAdd.vue"
              ),
          },
          {
            path: "data-entry/weight/add",
            name: "apps-data-entry-weight-add",
            component: () =>
              import(
                "@/views/apps/data-entry/data-entry-add/WeightListAdd.vue"
              ),
          },
          {
            path: "data-entry/bird-sale/add",
            name: "apps-data-entry-bird-sale-add",
            component: () =>
              import("@/views/apps/data-entry/data-entry-add/BirdSaleAdd.vue"),
          },
          {
            path: "data-entry/egg/add",
            name: "apps-data-entry-egg-add",
            component: () =>
              import(
                "@/views/apps/data-entry/data-entry-add/EggProductionAdd.vue"
              ),
          },
          {
            path: "data-entry/vaccine/add",
            name: "apps-data-entry-vaccine-add",
            component: () =>
              import(
                "@/views/apps/data-entry/data-entry-add/VaccineListAdd.vue"
              ),
          },
          {
            path: "data-entry/medicine/add",
            name: "apps-data-entry-medicine-add",
            component: () =>
              import("@/views/apps/data-entry/data-entry-add/MedicineAdd.vue"),
          },
          {
            path: "data-entry/mortality/add",
            name: "apps-data-entry-mortality-add",
            component: () =>
              import(
                "@/views/apps/data-entry/data-entry-add/MortalityListAdd.vue"
              ),
          },
          // {
          //   path: "/apps/data-entry/treatment/add",
          //   name: "apps-data-entry-treatment-add",
          //   component: () =>
          //     import("@/views/apps/data-entry/data-entry-add/TreatmentAdd.vue"),
          // },
          {
            path: "data-entry/water-consumption/add",
            name: "apps-data-entry-water-consumption-add",
            component: () =>
              import(
                "@/views/apps/data-entry/data-entry-add/WaterCompositionAdd.vue"
              ),
          },
          {
            path: "data-entry/chick-placement/add",
            name: "apps-data-entry-chick-placement-add",
            component: () =>
              import("@/views/apps/data-entry/data-entry-add/ChickPlacementAdd.vue"),
          },
          {
            path: "data-entry/chick-transfer/add",
            name: "apps-data-entry-chick-transfer-add",
            component: () =>
              import("@/views/apps/data-entry/data-entry-add/ChickTransferAdd.vue"),
          },
          // expense Entry
          {
            path: "expense-entry",
            name: "apps-expense-list",
            component: () =>
              import("@/views/apps/expense-entry/expense-list/ExpenseList"),
          },
          {
            path: "expense-entry",
            name: "apps-expense-add",
            component: () =>
              import("@/views/apps/expense-entry/expense-add/ExpenseAdd"),
          },
          {
            path: "expense-entry/:expenseId",
            name: "apps-expense-edit",
            component: () =>
              import("@/views/apps/expense-entry/expense-edit/ExpenseEdit"),
          },
          // machine list
          {
            path: "machine/setter",
            name: "apps-machine-setter",
            component: () =>
              import("@/views/apps/machine/machine-list/SetterList.vue"),
          },

          {
            path: "machine/hatcher",
            name: "apps-machine-hatcher",
            component: () =>
              import("@/views/apps/machine/machine-list/HatcherList.vue"),
          },

          // machine view
          {
            path: "machine/hatcher/view",
            name: "apps-machine-hatcher-view",
            component: () =>
              import("@/views/apps/machine/machine-list/HatcherView.vue"),
          },
          {
            path: "machine/setter/view",
            name: "apps-machine-setter-view",
            component: () =>
              import("@/views/apps/machine/machine-list/SetterView.vue"),
          },
          // device-add
          {
            path: "machine/hatcher/device/:farmId",
            name: "apps-machine-hatcher-device",
            component: () =>
              import("@/views/apps/machine/device/HatcherDevice.vue"),
          },
          {
            path: "machine/setter/device/:farmId",
            name: "apps-machine-setter-device",
            component: () =>
              import("@/views/apps/machine/device/SetterDevice.vue"),
          },
          // overview
          {
            path: "machine/:machineId/device/overview/:hatchId",
            name: "apps-machine-hatcher-device-overview",
            component: () =>
              import("@/views/apps/machine/machine-list/SetterOverview.vue"),
          },
          {
            path: "machine/device/overview/:farmId",
            name: "apps-machine-setter-device-overview",
            component: () =>
              import("@/views/apps/machine/machine-list/SetterOverview.vue"),
          },
          // hatch Summary
          {
            path: "hatchSummary/list",
            name: "apps-hatchSummary-list",
            component: () =>
              import("@/views/apps/hatch-summary/hatch-list/HatchSummary.vue"),
          },
          // alarm
          {
            path: "alarm",
            name: "apps-alarm",
            component: () =>
              import("@/views/apps/alarm/alarm-list/AlarmList.vue"),
          },
          // action-list
          {
            path: "action-list",
            name: "apps-action-list",
            component: () =>
              import("@/views/apps/action/action-list/ActionList.vue"),
          },
          // sale
          {
            path: "sale",
            name: "apps-sale",
            component: () => import("@/views/apps/sale/sale-list/SaleList.vue"),
          },
          {
            path: "sale/egg-sale",
            name: "apps-sale-egg-sale",
            component: () => import("@/views/apps/sale/egg-sale/EggSale.vue"),
          },
          {
            path: "sale/transfer-egg-sale",
            name: "apps-sale-transfer-egg-sale",
            component: () =>
              import(
                "@/views/apps/sale/transfer-eggs-sale/TransferEggSaleList.vue"
              ),
          },
          {
            path: "sale/litter-sale",
            name: "apps-sale-litter-sale",
            component: () =>
              import("@/views/apps/sale/litter-sale/LitterSaleList.vue"),
          },
          {
            path: "sale/litter-sale",
            name: "apps-sale-chick-sale-add",
            component: () =>
              import(
                "@/views/apps/sale/litter-sale/add-litter/AddLitterSale.vue"
              ),
          },
          //Data-Entry
          {
            path: "data-entry/list/:",
            name: "apps-data-entry-list",
            component: () =>
              import(
                "@/views/apps/data-entry/data-entry-list/DataEntryList.vue"
              ),
          },
          // {
          //   path: "data-entry/list",
          //   name: "apps-data-entry-list",
          //   component: () =>
          //     import("@/views/apps/data-entry/FlockDataEntryList.vue"),
          // },

          // Reports
          {
            path: "reports",
            name: "apps-reports",
            component: () => import("@/views/apps/reports/Reports.vue"),
          },
          {
            path: "/apps/reports/current-flock",
            name: "apps-reports-current-flock",
            component: () => import("@/views/apps/reports/CurrentReport.vue"),
          },
          {
            path: "/apps/reports/old-flock",
            name: "apps-reports-old-flock",
            component: () => import("@/views/apps/reports/OldReport.vue"),
          },

          // settings
          {
            path: "settings/hatchery-list",
            name: "apps-settings-hatchery-list",
            component: () =>
              // import("@/views/apps/settings/SettingHatchery.vue"),
              import("@/views/apps/settings/Settings.vue"),
          },
          {
            path: "settings",
            name: "apps-settings",
            component: () => import("@/views/apps/settings/Settings.vue"),
          },
          // User
          // {
          // 	path: "/apps/",
          // 	name: "apps-permission",
          // 	component: () => import("@/views/apps/permission/Permission.vue"),
          // },
          // {
          // 	path: "/apps/settings",
          // 	name: "apps-settings",
          // 	component: () => import("@/views/apps/settings/Settings.vue"),
          // },
          // *===============================================---*
          // *--------- USER ---- ---------------------------------------*
          // *===============================================---*
        ],
      },
    ],
  },
];
